export const marketingUrl =
  process.env.MARKETING_URL || "https://flicket.co.nz";

export function marketingOptInCopy(
  organization: { name: string },
  isAdmin = false
): string {
  const title = isAdmin ? "Customer wants" : "I would like";
  const name = organization.name ?? "Flicket";

  return `${title} to be updated on ${name} news, events and offers.`;
}
