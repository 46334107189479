import { ReactNode } from "react";
import { Box, SystemProps } from "flicket-ui";
import { useRouter } from "next/router";

import { DesktopMenu } from "~components/common/Menu/menu.Desktop";
import { MobileMenu } from "~components/common/Menu/menu.Mobile";
import { useAccount } from "~hooks/useAccount";

interface MenuProps extends SystemProps {
  name?: string;
  loggedIn?: boolean;
  children?: ReactNode;
}

export const Menu = ({ children, ...props }: MenuProps) => {
  const router = useRouter();
  const { user, logout, authState } = useAccount();

  return (
    <Box width="100%" {...props} position="fixed" top={0} zIndex="sticky">
      <Box display={{ _: "block", md: "none" }}>
        <MobileMenu
          isLoggedIn={authState !== "authenticating"}
          pathname={router?.pathname}
          logout={() => {
            void logout();
          }}
        >
          {children}
        </MobileMenu>
      </Box>
      <Box display={{ _: "none", md: "block" }}>
        <DesktopMenu
          pathname={router?.pathname}
          firstName={user?.firstName}
          lastName={user?.lastName}
          authState={authState}
        />
      </Box>
    </Box>
  );
};

export default Menu;
